// extracted by mini-css-extract-plugin
export var accordion = "IdxPackages-module--accordion--b5a69";
export var accordionContent = "IdxPackages-module--accordion-content--61ade";
export var accordionHeading = "IdxPackages-module--accordion-heading--4985f";
export var accordionParent = "IdxPackages-module--accordion-parent--47684";
export var addOns = "IdxPackages-module--add-ons--a862e";
export var addOnsContent = "IdxPackages-module--add-ons-content--74e7d";
export var addOnsTerms = "IdxPackages-module--add-ons-terms--949da";
export var background = "IdxPackages-module--background--c3fb7";
export var banner = "IdxPackages-module--banner--02303";
export var bannerImage = "IdxPackages-module--banner-image--441db";
export var border = "IdxPackages-module--border--648d6";
export var button = "IdxPackages-module--button--a191e";
export var content = "IdxPackages-module--content--1b35a";
export var cta = "IdxPackages-module--cta--d78f4";
export var description = "IdxPackages-module--description--15802";
export var designPackage = "IdxPackages-module--design-package--44eee";
export var faqs = "IdxPackages-module--faqs--89607";
export var faqsContent = "IdxPackages-module--faqs-content--701be";
export var faqsItems = "IdxPackages-module--faqs-items--59fd5";
export var featuredCards = "IdxPackages-module--featured-cards--6fb89";
export var features = "IdxPackages-module--features--a17ae";
export var flexibleSolutions = "IdxPackages-module--flexible-solutions--abfde";
export var heading = "IdxPackages-module--heading--8d256";
export var headingContent = "IdxPackages-module--heading-content--4fd92";
export var idxSolutions = "IdxPackages-module--idx-solutions--f6421";
export var idxStudio = "IdxPackages-module--idx-studio--3e989";
export var image = "IdxPackages-module--image--7a977";
export var imageWrapper = "IdxPackages-module--image-wrapper--db11f";
export var inclusion = "IdxPackages-module--inclusion--c7188";
export var inclusions = "IdxPackages-module--inclusions--97721";
export var item = "IdxPackages-module--item--6cb20";
export var items = "IdxPackages-module--items--0a62c";
export var last = "IdxPackages-module--last--b92df";
export var lastAccordion = "IdxPackages-module--last-accordion--4076c";
export var packageInclusions = "IdxPackages-module--package-inclusions--a45c7";
export var subDescription = "IdxPackages-module--sub-description--bef00";
export var subtitle = "IdxPackages-module--subtitle--2c1f3";
export var tabletFeatures = "IdxPackages-module--tablet-features--df676";
export var text = "IdxPackages-module--text--b5655";
export var websitePackages = "IdxPackages-module--website-packages--c817b";