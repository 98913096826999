import React from 'react'

const SvgCheck = ({ color = '#B1B1C0' }) => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5 11.25C22.5 17.4632 17.4632 22.5 11.25 22.5C5.0368 22.5 0 17.4632 0 11.25C0 5.0368 5.0368 0 11.25 0C17.4632 0 22.5 5.0368 22.5 11.25ZM8.3052 10.2025C8.44246 10.2615 8.56659 10.3472 8.67037 10.4546L10.125 11.9093L13.8296 8.20465C13.9334 8.0972 14.0575 8.01149 14.1948 7.95253C14.332 7.89357 14.4797 7.86254 14.629 7.86124C14.7784 7.85994 14.9266 7.88841 15.0648 7.94497C15.2031 8.00154 15.3287 8.08507 15.4343 8.1907C15.5399 8.29633 15.6235 8.42194 15.68 8.5602C15.7366 8.69846 15.7651 8.8466 15.7638 8.99597C15.7625 9.14535 15.7314 9.29297 15.6725 9.43023C15.6135 9.56748 15.5278 9.69162 15.4204 9.7954L10.9204 14.2954C10.7094 14.5063 10.4233 14.6248 10.125 14.6248C9.82669 14.6248 9.54059 14.5063 9.32962 14.2954L7.07962 12.0454C6.97217 11.9416 6.88647 11.8175 6.82751 11.6802C6.76855 11.543 6.73751 11.3954 6.73622 11.246C6.73492 11.0966 6.76338 10.9485 6.81995 10.8102C6.87651 10.6719 6.96005 10.5463 7.06568 10.4407C7.17131 10.3351 7.29692 10.2515 7.43517 10.195C7.57343 10.1384 7.72157 10.1099 7.87095 10.1112C8.02032 10.1125 8.16795 10.1436 8.3052 10.2025Z"
        fill={color}
      />
    </svg>
  )
}

export default SvgCheck
