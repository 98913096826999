import React, { useState, useEffect } from 'react'
import ElementImage from '@components/global/element/Image'
import useMedia, { media } from '@hooks/useMedia'
import clsx from 'clsx'

import * as cx from './InclusionCard.module.scss'

const InclusionCard = ({ imgSrc, data }) => {
  const isDeviceMinTablet = useMedia(media.minTablet)
  const [isMinTablet, setIsMinTablet] = useState(false)

  useEffect(() => {
    setIsMinTablet(isDeviceMinTablet)
  }, [isDeviceMinTablet])

  return (
    <div className={cx.wrapper}>
      <div className={cx.content}>
        <ElementImage src={imgSrc} alt={data.title} height={65} />
        <h3
          className={clsx(
            isMinTablet && 'subtitle-9',
            !isMinTablet && 'heading-3'
          )}
        >
          {data.title}
        </h3>
        <p className="default-body large">{data.description}</p>
      </div>
    </div>
  )
}

export default InclusionCard
