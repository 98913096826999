import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { Link } from 'gatsby'
import parse from 'html-react-parser'
import useMedia, { media } from '@hooks/useMedia'

import ElementImage from '@components/global/element/Image'

import * as cx from './FeaturedCard.module.scss'

import SvgCheck from '@svg/check.inline'

const FeaturedCard = ({ data, imageSrc }) => {
  const { title, subtitle, descriptionHeader, description, features } = data

  const isDeviceMinTablet = useMedia(media.minTablet)
  const [isMinTablet, setIsMinTablet] = useState(false)

  useEffect(() => {
    setIsMinTablet(isDeviceMinTablet)
  }, [isDeviceMinTablet])

  return (
    <div className={clsx(cx.wrapper)}>
      <ElementImage src={imageSrc} alt="Featured Package" />
      <div className={clsx(cx.content)}>
        <h5
          className={clsx(
            isMinTablet && 'subtitle-2',
            !isMinTablet && 'heading-5'
          )}
        >
          {title}
        </h5>
        <p className={clsx('subtitle-7', cx.subtitle)}>{subtitle}</p>
        <p className="small-heading strong">{parse(descriptionHeader)}</p>
        <p className="default-body large">{parse(description)}</p>
      </div>
      <div className={clsx(cx.divider)} />
      <div className={clsx(cx.features)}>
        {features.map((feature, key) => (
          <div key={key} className={clsx(cx.feature)}>
            <SvgCheck color={feature.active ? '#39C5ED' : '#B1B1C0'} />
            <span className="default-body large">{feature.title}</span>
          </div>
        ))}
      </div>
      <div className={clsx(cx.button)}>
        <Link to="/" className="primary-button large">
          Get features & pricing
        </Link>
      </div>
    </div>
  )
}

export default FeaturedCard
