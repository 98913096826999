import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import parse from 'html-react-parser'

import * as cx from './IdxPackagesModuleTabs.module.scss'

import ModuleTabsItem from './ModuleTabsItem'

const IdxPackagesModuleTabs = ({ background, items, placeholder }) => {
  const [active, useActive] = useState(items[0]?.title)

  return (
    <section>
      <div className={clsx(cx.menus)}>
        <ul>
          {items.map((item, index) => {
            return (
              <li
                key={index}
                className={clsx(active === item.title && cx.active)}
              >
                <button
                  className="small-heading strong"
                  onClick={() => {
                    useActive(item.title)
                  }}
                >
                  {parse(item.title)}
                </button>
              </li>
            )
          })}
        </ul>
      </div>
      <div
        className={clsx(cx.contents)}
        style={{ backgroundImage: `url(${background})` }}
      >
        {items.map((item, index) => {
          return (
            <div
              key={index}
              className={clsx(cx.content, active === item.title && cx.active)}
            >
              <ModuleTabsItem item={item} placeholder={placeholder} />
            </div>
          )
        })}
      </div>
    </section>
  )
}

IdxPackagesModuleTabs.propTypes = {
  background: PropTypes.string,
  items: PropTypes.array,
  placeholder: PropTypes.any,
}

export default IdxPackagesModuleTabs
