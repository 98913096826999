import React, { useState, useEffect } from 'react'
import { Link, graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import parse from 'html-react-parser'
import clsx from 'clsx'
import {
  featuredPackages,
  websitePackages,
  packageInclusions,
  iHomefinderAddOns,
  terms,
  idxSolutions,
  idxStudio,
  idxPackages,
  idxFaq,
} from '@src/data/IDXStudio'
import useIDXStudio from '@hooks/idx-studio-metadata'
import useMedia, { media } from '@hooks/useMedia'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'
import { removeElementTags } from '@utils/removeElementTags'

// Components
import ElementImage from '@components/global/element/Image'
import ElementImageFile from '@components/global/element/ImageFile'
import Seo from '@components/seo/Index'
import LayoutInnerPage from '@src/layouts/InnerPage'
import Banner from '@components/global/banner/Banner'
import FeaturedCard from '@components/pages/resources/idx-packages/FeaturedCard'
import PackagesCard from '@components/pages/resources/idx-packages/PackagesCard'
import InclusionCard from '@components/pages/resources/idx-packages/InclusionCard'
import SvgCheckedCircle from '@svg/checkedCircle.inline'
import ToggleAccordion from '@components/global/accordions/ToggleAccordion'
import IDXAds from '@components/pages/resources/idx-ads'
import IdxPackagesModuleTabs from '@components/pages/resources/idx-packages/ModuleTabs'
import Faqs from '@components/global/accordions/Faqs'
import LeadContactForm from '@components/global/forms/LeadContactForm/Index'

import * as cx from './IdxPackages.module.scss'

// Images
import inclusion1 from '/content/assets/idx-studio/inclusions/inclusion-1.svg'
import inclusion2 from '/content/assets/idx-studio/inclusions/inclusion-2.svg'
import inclusion3 from '/content/assets/idx-studio/inclusions/inclusion-3.svg'
import inclusion4 from '/content/assets/idx-studio/inclusions/inclusion-4.svg'

const IDXPackages = ({ data: { page }, pageContext: { seo, uri } }) => {
  const isDeviceMinTablet = useMedia(media.minTablet)
  const isDeviceDesktop = useMedia(media.desktop)
  const isDeviceTablet = useMedia(media.tablet)
  const isDeviceMobile = useMedia(media.phone)

  const [isMinTablet, setIsMinTablet] = useState(false)
  const [isDesktop, setIsDesktop] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const [isTablet, setIsTablet] = useState(false)

  const { magnifyLaptop } = useIDXStudio()

  const data = useIDXStudio()

  const featuredImages = [
    data.featuredImage1,
    data.featuredImage2,
    data.featuredImage3,
  ]
  const packageImages = [
    data.package1,
    data.package2,
    data.package3,
    data.package4,
    data.package5,
    data.package6,
  ]
  const inclusions = [inclusion1, inclusion2, inclusion3, inclusion4]

  useEffect(() => {
    setIsMinTablet(isDeviceMinTablet)
    setIsDesktop(isDeviceDesktop)
    setIsTablet(isDeviceTablet)
    setIsMobile(isDeviceMobile)
  }, [isDeviceMinTablet, isDeviceDesktop, isDeviceTablet, isDeviceMobile])

  return (
    <LayoutInnerPage>
      <Seo title={page.title} seo={seo} uri={uri} />
      <Banner
        wrapperClassName={clsx(cx.banner)}
        layout="product-gallery"
        bgImage={extractImage(data.bannerBG)}
        breadcrumbs={page?.seo?.breadcrumbs}
        title={page.title}
        titleClassName={clsx(!isTablet && 'heading-2', isTablet && 'heading-3')}
        description="IDX Solutions for Agent and Broker Websites"
        descriptionClassName={clsx(
          isDesktop && 'subtitle-6',
          !isDesktop && 'subtitle-5'
        )}
        formLayout="default"
        formHeadingClassName="subtitle-6"
        devices={{
          layout: '',
          // layout: 'TabletLaptopMagnify',
          // magnify: extractImage(bannerMagnify),
          // tablet: extractImage(bannerTablet),
          // laptop: extractImage(bannerLaptop),
        }}
      >
        <ElementImageFile
          className={clsx(cx.bannerImage, 'desktop-only')}
          src={getImage(data.bannerImage)}
          alt={removeElementTags(page.title)}
          lazy={false}
        />
        <ElementImageFile
          className={clsx(cx.bannerImage, 'tablet-only')}
          src={getImage(data.bannerImageTablet)}
          alt={removeElementTags(page.title)}
          lazy={false}
        />
        <ElementImageFile
          className={clsx(cx.bannerImage, 'mobile-no-tablet-only')}
          src={getImage(data.bannerImageMobile)}
          alt={removeElementTags(page.title)}
          lazy={false}
        />
      </Banner>

      <section className={clsx(cx.flexibleSolutions)}>
        <h5 className={clsx(isTablet && 'heading-2', !isTablet && 'heading-5')}>
          IDX Real Estate Wesbites
        </h5>
        <p
          className={clsx(
            isDesktop && 'subtitle-1',
            !isDesktop && 'subtitle-7'
          )}
        >
          Flexible Solutions For Agents, Brokers and Teams
        </p>
      </section>

      <section
        className={clsx(cx.featuredCards)}
        style={{ backgroundImage: `url(${extractImage(data.featuresBG)})` }}
      >
        <div className={clsx(cx.content, 'container')}>
          <div className={clsx(cx.items, 'row')}>
            {featuredImages.map((featuredImage, index) => (
              <div key={index} className={clsx(cx.item, 'col')}>
                <FeaturedCard
                  imageSrc={extractImage(featuredImage)}
                  data={featuredPackages[index]}
                />
              </div>
            ))}
          </div>
        </div>
      </section>

      <section
        className={clsx(cx.designPackage)}
        style={{ backgroundImage: `url(${extractImage(data.designPackage)})` }}
      >
        <div className={cx.content}>
          <h5 className={clsx('heading-5', cx.heading)}>
            IDX Website Packages
          </h5>
          <p className="default-body large">
            We offer three different real estate website design packages to meet
            every budget. These offer all the features of the iHomefinder
            websites above, plus many more options. Our custom website packages
            are worth it! We use iHomefinder as the IDX for your custom real
            estate website. Every website is also mobile device compatible.
            <br />
            <br />
            Take a look at some examples of IDX pages in action.
          </p>
        </div>
      </section>

      <section
        className={clsx(cx.websitePackages)}
        style={{
          backgroundImage: `url(${extractImage(data.packagesBackground)})`,
        }}
      >
        <div className={clsx(cx.items)}>
          {packageImages.map((image, index) => (
            <PackagesCard
              key={index}
              className={clsx(cx.item)}
              isIdx={websitePackages[index]?.isIdx}
              title={websitePackages[index]?.title}
              description={websitePackages[index]?.description}
              imgSrc={extractImage(image)}
            />
          ))}
        </div>
      </section>

      <section className={clsx(cx.cta)}>
        <div className={clsx('container', cx.content)}>
          <div className="row">
            <div className={clsx(cx.text, 'col')}>
              <h2
                className={clsx(
                  isDesktop && 'heading-3',
                  !isDesktop && 'heading-2'
                )}
              >
                Not sure where to begin with your IDX Package?
              </h2>
              <p className={clsx('default-body', !isDesktop && 'large')}>
                Click below to schedule a Free IDX Consultation <br />
                with our expert Web Marketing Strategists.
              </p>
              <Link to="/" className="primary-button large">
                Learn More
              </Link>
            </div>
            <div className={clsx(cx.image, 'col')}>
              <ElementImage
                src={extractImage(magnifyLaptop)}
                alt="Not sure where to begin with your IDX Package?"
              />
            </div>
          </div>
        </div>
      </section>

      <section
        className={clsx(cx.packageInclusions)}
        style={{
          backgroundImage: `url(${extractImage(data.packagesBackground)})`,
        }}
      >
        <h5 className={clsx('heading-5', cx.heading)}>
          IDX Package Inclusions
        </h5>
        <div className={clsx(cx.inclusions)}>
          {inclusions.map((image, index) => (
            <div key={index} className={clsx(cx.inclusion)}>
              <InclusionCard imgSrc={image} data={packageInclusions[index]} />
            </div>
          ))}
        </div>

        <div className={clsx(cx.addOnsTerms)}>
          <div className={clsx(cx.addOnsContent)}>
            <div className={clsx(cx.imageWrapper)}>
              <ElementImage
                src={extractImage(data.magnifyTablet)}
                alt="iHomefinder Add-ons"
                // className={cx.imageMobile}
              />
            </div>
            <div className={clsx(cx.content)}>
              <div className={clsx(cx.addOns)}>
                <h4
                  className={clsx(
                    isMinTablet && 'subtitle-3',
                    !isMinTablet && 'subtitle-9'
                  )}
                >
                  {iHomefinderAddOns.title}
                </h4>
                {iHomefinderAddOns.addOns.map(
                  ({ heading, subHeading }, index) => (
                    <p key={index} className="default-body large">
                      <span className="small-heading strong">{heading}</span>
                      {subHeading}
                    </p>
                  )
                )}
              </div>
              <div className={clsx(cx.border)}></div>
              <div className={clsx(cx.features)}>
                <h4
                  className={clsx(
                    isMinTablet && 'subtitle-3',
                    !isMinTablet && 'subtitle-9'
                  )}
                >
                  Features
                </h4>
                <ul>
                  {iHomefinderAddOns.features.map((feature, index) => (
                    <li key={index}>
                      <SvgCheckedCircle />
                      <span className="default-body large">
                        {parse(feature)}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className={clsx(cx.tabletFeatures)}>
            <h4 className="subtitle-3">Features</h4>
            <ul>
              {iHomefinderAddOns.features.map((feature, index) => (
                <li key={index}>
                  <SvgCheckedCircle />
                  <span className="default-body large">{parse(feature)}</span>
                </li>
              ))}
            </ul>
          </div>
          <ToggleAccordion
            title="Terms"
            parentClass={clsx(cx.accordionParent)}
            childClass={clsx(cx.accordionContent)}
            btnClass={clsx(cx.accordionHeading, 'subtitle-3')}
          >
            <ul>
              {terms.map((term, index) => (
                <li key={index} className="list-disc default-body large">
                  {term}
                </li>
              ))}
            </ul>
          </ToggleAccordion>
        </div>
      </section>
      <IDXAds skip="IDX Packages" />
      <section className={clsx(cx.idxSolutions)}>
        <div className={clsx(cx.heading)}>
          <h2 className="heading-5">{idxSolutions.title}</h2>
        </div>
        <div className={clsx(cx.content)}>
          <div className={clsx(cx.description)}>
            {idxSolutions.description.map((description, index) => {
              return (
                <p
                  key={index}
                  className={clsx(
                    'default-body large',
                    index === idxSolutions.description.length - 1 && cx.last
                  )}
                >
                  {description}
                </p>
              )
            })}
            {idxSolutions.faqs.map((faq, index) => {
              return (
                <ToggleAccordion
                  key={index}
                  parentClass={clsx(
                    cx.accordion,
                    index === idxSolutions.faqs.length - 1 && cx.lastAccordion
                  )}
                  title={parse(faq.title)}
                  btnClass={clsx('subtitle-3', cx.button)}
                  childClass={clsx(cx.subDescription)}
                >
                  {faq.description.map((description, index) => {
                    return (
                      <p
                        key={index}
                        className={clsx(
                          'default-body large',
                          index === idxSolutions.faqs.length - 1 && cx.last
                        )}
                      >
                        {description}
                      </p>
                    )
                  })}
                </ToggleAccordion>
              )
            })}
          </div>
          <div className={clsx(cx.image)}>
            <ElementImageFile
              src={getImage(data.idxSolutionsIpad)}
              alt={removeElementTags(idxSolutions.title)}
              lazy={false}
            />
          </div>
        </div>
      </section>
      <section className={clsx(cx.idxStudio)}>
        <div className={clsx(cx.headingContent)}>
          <ElementImageFile
            className={clsx(cx.background)}
            src={getImage(data.idxStudioBackground)}
            alt={removeElementTags(idxStudio.title)}
          />
          <div className={clsx(cx.heading)}>
            <h2
              className={clsx(
                isMinTablet && 'heading-5',
                !isMinTablet && 'heading-2'
              )}
            >
              {idxStudio.title}
            </h2>
            <p className={clsx('subtitle-7', cx.subtitle)}>
              {idxStudio.subtitle}
            </p>
            <p className="default-body large">{idxStudio.description}</p>
          </div>
          <div className={clsx(cx.image)}>
            <ElementImageFile
              src={getImage(data.idxStudio)}
              alt={removeElementTags(idxStudio.title)}
            />
          </div>
        </div>
        <div className={clsx(cx.faqsContent)}>
          <ElementImageFile
            className={clsx(cx.background)}
            src={getImage(data.idxStudioFaqBackground)}
            alt={removeElementTags(idxStudio.title)}
          />
          <div className={clsx(cx.content)}>
            <div className={clsx(cx.description)}>
              <h3
                className={clsx(
                  isMinTablet && 'subtitle-2',
                  !isMinTablet && 'subtitle-9'
                )}
              >
                {parse(idxStudio.faqs.title)}
              </h3>
              {idxStudio.faqs.description.map((description, index) => {
                return (
                  <p
                    key={index}
                    className={clsx(
                      'default-body large',
                      index === idxStudio.faqs.description.length - 1 && cx.last
                    )}
                  >
                    {parse(description)}
                  </p>
                )
              })}
            </div>
            <div className={clsx(cx.faqs)}>
              {idxStudio.faqs.items.map((faq, index) => {
                return (
                  <ToggleAccordion
                    key={index}
                    parentClass={clsx(
                      cx.accordion,
                      index === idxStudio.faqs.items.length - 1 &&
                        cx.lastAccordion
                    )}
                    title={parse(faq.title)}
                    btnClass={clsx('subtitle-3', cx.button)}
                    childClass={clsx(cx.subDescription)}
                  >
                    {faq.description.map((description, index) => {
                      return (
                        <p
                          key={index}
                          className={clsx(
                            'default-body large',
                            index === idxStudio.faqs.items.length - 1 && cx.last
                          )}
                        >
                          {description}
                        </p>
                      )
                    })}
                  </ToggleAccordion>
                )
              })}
            </div>
          </div>
        </div>
      </section>
      <IdxPackagesModuleTabs
        background={extractImage(data.idxStudioFaqBackground)}
        items={idxPackages}
        placeholder={data.ModularTabs}
      />
      <Faqs
        noZoomEffect
        liftContent
        extendTitleSpace
        bgImg={extractImage(data.FaqBg)}
        items={idxFaq}
        wrapperClassName={cx.faqsItems}
        imageHeight={1053}
      />
      <LeadContactForm
        version="LCF05"
        modelOrBackground="TrevorAlt"
        title="Free Consultation"
        subtitle="Get in touch with us for a"
        submitLabel="I want a stunning website!"
        leadName="IDX Packages"
        layout="default"
      />
    </LayoutInnerPage>
  )
}

export default IDXPackages

export const IDXPackagesPageQuery = graphql`
  query IDXCoveragePageById($id: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      content
      title
      date(formatString: "MMMM DD, YYYY")
      seo {
        breadcrumbs {
          text
          url
        }
      }
    }
  }
`
