// extracted by mini-css-extract-plugin
export var active = "ModuleTabsItem-module--active--051c3";
export var alt = "ModuleTabsItem-module--alt--048c4";
export var border = "ModuleTabsItem-module--border--a934b";
export var descriptionSpace = "ModuleTabsItem-module--description-space--f7fca";
export var empty = "ModuleTabsItem-module--empty--f6b7c";
export var first = "ModuleTabsItem-module--first--6e4d3";
export var heading = "ModuleTabsItem-module--heading--0d16a";
export var image = "ModuleTabsItem-module--image--58390";
export var inclusion = "ModuleTabsItem-module--inclusion--b9147";
export var inclusions = "ModuleTabsItem-module--inclusions--3a80b";
export var inclusionsContent = "ModuleTabsItem-module--inclusions-content--a7762";
export var inclusionsContentActive = "ModuleTabsItem-module--inclusions-content-active--b0600";
export var item = "ModuleTabsItem-module--item--3e430";
export var layoutCards = "ModuleTabsItem-module--layout-cards--4a19e";
export var layoutInclusions = "ModuleTabsItem-module--layout-inclusions--3181b";
export var layoutWithoutImage = "ModuleTabsItem-module--layout-without-image--5a571";
export var readMore = "ModuleTabsItem-module--read-more--80ea2";
export var second = "ModuleTabsItem-module--second--173a2";
export var secondActive = "ModuleTabsItem-module--second-active--03e35";
export var subContent = "ModuleTabsItem-module--sub-content--31b80";