export const featuredPackages = [
  {
    title: 'iHomefinder Pro',
    subtitle: 'agent idx',
    descriptionHeader: 'designed for a single agent.',
    description:
      'This package offers everything one agent needs including customizable pages, custom real estate tools, and an interactive map search.',
    features: [
      {
        title: 'Up to ten agents',
        active: true,
      },
      {
        title: 'Interactive map search',
        active: true,
      },
      {
        title: 'Unlimited office pages',
        active: false,
      },
      {
        title: 'Property slideshow of selected properties',
        active: false,
      },
    ],
  },
  {
    title: 'iHomefinder Pro',
    subtitle: 'agent team',
    descriptionHeader: 'designed for a two agents.',
    description:
      'This package includes customizable pages and an interactive map search.',
    features: [
      {
        title: 'Up to ten agents',
        active: true,
      },
      {
        title: 'Interactive map search',
        active: true,
      },
      {
        title: 'Unlimited office pages',
        active: false,
      },
      {
        title: 'Property slideshow of selected properties',
        active: false,
      },
    ],
  },
  {
    title: 'iHomefinder Pro',
    subtitle: 'idx team',
    descriptionHeader: 'Designed for up to <br />10 agents and brokers',
    description:
      'with the ability to add up to 50 users as an optional add-on. This package includes unlimited customizable pages, a property slideshow, and an interactive map search.',
    features: [
      {
        title: 'Up to ten agents',
        active: true,
      },
      {
        title: 'Interactive map search',
        active: true,
      },
      {
        title: 'Unlimited office pages',
        active: true,
      },
      {
        title: 'Property slideshow of selected properties',
        active: true,
      },
    ],
  },
]

export const websitePackages = [
  {
    title: 'iHomefinder',
    description:
      'Establish a professional web presence to nurture prospective clients and gather new leads.',
    isIdx: false,
  },
  {
    title: 'iHomefinder <br />MarketBoost + Eureka',
    description:
      'Automate your follow-up and lead engagement with ready-to-use email campaigns.',
    isIdx: false,
  },
  {
    title: 'iHomefinder ONE',
    description:
      'Fully integrated real estate sales and marketing platform with CRM, IDX, and lead generation in a single package.',
    isIdx: false,
  },
  {
    title: 'iHomefinder AGENT CRM',
    description:
      'Automate your follow-up and lead engagement with ready-to-use email campaigns.',
    isIdx: false,
  },
  {
    title: 'IDX Broker Lite',
    description:
      'For real estate professionals looking to get started with IDX home search essentials.',
    isIdx: true,
  },
  {
    title: 'IDX Broker Platinum',
    description:
      'For real estate professionals looking to advance their online presence and boost SEO.',
    isIdx: true,
  },
]

export const packageInclusions = [
  {
    title: 'How To',
    description: 'IDX Studio walk-through & tutorial',
  },
  {
    title: 'Quick Search',
    description:
      'Basic Quick Search setup on the custom homepage (property type, city, bedrooms, bathrooms, and price). Additional parameters may come at an additional cost.',
  },
  {
    title: 'Design',
    description:
      'Custom HTML skinning of IDX pages to ensure seamless compatibility with your real estate website design.',
  },
  {
    title: 'Communities',
    description:
      'Creation of up to 5 hotsheets. Instructions will be given if you need to make more. If you don’t have the time, we can make more for you at $10 each.',
  },
]

export const iHomefinderAddOns = {
  title: 'iHomefinder Add-ons',
  addOns: [
    {
      heading: 'ADDITIONAL 50 USERS',
      subHeading: 'for Broker IDX Package',
    },
    {
      heading: 'ADDITIONAL MLS BOARD',
      subHeading: 'for Pro Agent IDX/Team',
    },
    {
      heading: 'ADDITIONAL MLS BOARD',
      subHeading: 'for Broker Package',
    },
  ],
  features: [
    'Add a block of 50 additional users for a Broker IDX <br />package',
    'Add another MLS Board to your existing iHomefinder Pro Agent IDX or Pro Agent Team',
    'Add another MLS Board to your existing iHomefinder Broker Package',
  ],
}

export const terms = [
  'Direct or Pass-Thru Fees may be applicable, depending on your MLS Board.',
  'A Direct Fee is a fee that the MLS Board charges directly to a client for getting IDX Services. Please contact your MLS Board for details, as fees can vary per area.',
  'A Pass-Thru Fee is a fee charged by the MLS Board to the IDX Vendor, who charges us in turn for applying the IDX Services. This charge is added to the client’s fees. For example, if an MLS Board charges a $5 Pass-Thru Fee, Agent Image will be charging the client an additional $5 fee/month.',
  'A $100 cancellation fee will be charged for pre-termination within less than 6 months after signing up.',
  'The listing and detail layouts are different for iHomefinder and for IDX Broker’s IDX services.',
  'Agent Image will be applying the default layouts available for each IDX Vendor’s services. Any possible customization will be subject to availability c/o the limitations of the IDX Vendors and may be subject to custom quote. Please consult with our Web Marketing Strategists and Project Managers to determine feasibility of any layout customization.',
]

export const idxSolutions = {
  title: 'Agent Image IDX Solutions',
  description: [
    'If you are in the market for a real estate website, you’re in the right place! Agent Image specializes in creating high-quality, lead generating agent websites. Our design services are top-notch and our designers understand both the needs of agents and real estate website design.',
    'We’re experts in real estate marketing and offer packages for even the largest real estate agent business. Agent Images also offers social media management. We pride ourselves on offering some of the best real estate agent websites and look forward to creating the best real estate website for you.',
    'Agent Image uses IDX websites that bring business to your company and help you to generate leads. Chat with one of our designers to see what we can cater to your website needs.',
  ],
  faqs: [
    {
      title: 'What is IDX?',
      description: [
        'IDX stands for Internet Data Exchange and covers the legal and technical aspects of displaying real estate listing information on an agent website.',
        'Your agent website needs IDX to properly run an MLS (multiple listing service) and to update listings hourly.',
        'You can offer MLS listings without IDX but the listings do not update regularly and may be out of date in today’s hot market. For instance, Zillow does not use IDX and research shows that up to 36% of listings are no longer for sale and about 20% of active listings on MLS do not show up on their website.',
      ],
    },
    {
      title: 'What is the difference<br /> between MLS and IDX?',
      description: [
        'Multiple listing service (MLS) is a way to upload, manage, and share listings from a specific area. Listings include photos, descriptions, special features, and any other details the real estate agent needs to know to show the property.',
        'It started as a paper book in the late 1800s but that went by the wayside with the advent of the internet.',
        'Under some situations, the MLS listings can be viewed by the public through a portal similiar to those offered by Realtor.com, Zillow, and Redfin.',
        'IDX is software that allows anyone with an internet connection to access listings. Since over 90% of buyers start their home searches online, an IDX portal is extremely important for your agent website. Furthermore, as the IDX is connected to the local MLS and updated regularly, it offers the most recent information on every listing including current prices. Since this is a requirement of real estate boards, real estate website design must include the most up-to-date information.',
      ],
    },
    {
      title: 'How do I get IDX on my real estate<br /> website?',
      description: [
        'Getting IDX on your agent website is relatively simple. Once you purchase the software, you can use the codes or plugins in your own site setup.',
        'IDX Broker costs about $50 a month, is highly regarded as easily customizable, and regularly offers improvements, including a Facebook app. Agent Image offers real estate websites using IDX Broker.',
        'Agent Image also uses iHomefinder in our IDX custom websites. iHomefinder is a leading provider that boasts strict compliance with IDX rules, helping real estate agents comply with MLS requirements. They are also noted for their excellent map search features.',
        'We offer three iHomefinder packages for one to ten agents and can seamlessly integrate IDX technology into real estate websites. For more information on Agent Image pricing, please scroll down for details.',
      ],
    },
  ],
}

export const idxStudio = {
  title: 'IDX Studio',
  subtitle: 'IDX Solutions for Agent and Broker Websites',
  description:
    'As you scroll through our IDX website options, keep in mind the features that are absolutely necessary for great real estate websites. These will help you find the best package for your individual real estate website. Our designers are always willing to chat with you.',
  faqs: {
    title: 'What Do Great <br/>Real Estate Websites Need?',
    description: [
      'A real estate website is one of the best marketing tools in your arsenal. Without a good, usable, user-friendly site, no one can find your business online. Add to your real estate marketing power with website design services that can drive business in your direction.',
      'Real estate website design is a specialty that needs someone who understands the needs of the real estate agent. Every Agent Image website is created with a real estate agent’s needs in mind. Our design people understand what goals you need to pursue and help you accomplish them.',
      'These are the bare minimum that you need for great real estate website design.',
      'With those in mind, let’s look at our packages. If you need Agent Image pricing, click on the orange buttons.',
    ],
    items: [
      {
        title: 'Neighborhood Information',
        description: [
          'For each neighborhood, consider creating a page with annual events, types of houses, resident testimonials, schools, and other reasons that people should want to buy a house in that area.',
        ],
      },
      {
        title: 'Listings',
        description: [
          'There are some real estate agents who wish to provide buyers with their top choices for real estate matching their criteria. Since most home buyers start their search online, your website should include listings using IDX for the most recent information. You can fine-tune your client’s list as you talk with them.',
        ],
      },
      {
        title: 'Featured Searches',
        description: [
          'This function allows the buyer to input their preferred home specs. These could include school zones, price ranges, amenities like pools, or neighborhoods. You can track which features are most popular and see what you can refine or eliminate. Offering an advanced search can help the home buyer find a property far more quickly than the basic version.',
        ],
      },
      {
        title: 'Lead Capture Landing Pages',
        description: [
          'Since you want someone to contact you, make it easy for them! On every page, have your phone number, email, or contact forms. They don’t have to be annoying — just present and working.',
        ],
      },
      {
        title: 'About You',
        description: [
          'This page should tell the potential real estate client why they should choose you, what they can expect from you, what your specialties are, etc. You may want a section on why using a real estate agent to buy or sell property is important.',
        ],
      },
      {
        title: 'Interactive Map Search',
        description: [
          'People want to see the neighborhood and local roads. Having an interactive map will let them see where the home is and what is nearby. Plus, maybe the house they were originally interested in is not perfect, but the house two streets over is.',
        ],
      },
    ],
  },
}

export const idxPackages = [
  {
    layout: 'cards',
    title: 'Terminology',
    description: [
      'You’ll encounter the terms below frequently as you peruse our packages, so let’s define them first.',
    ],
    items: [
      {
        title: 'Landing Pages',
        description:
          'Landing pages pop up when a potential client clicks on the website page. Custom landing pages can generate leads.',
      },
      {
        title: 'Homepage',
        description:
          'The homepage is usually the first page a person sees when accessing a website. It should act almost like a table of contents, guiding users to different pages and stoking their interest. A custom home page is an important part of real estate website design.',
      },
      {
        title: 'SEO-friendly',
        description:
          'SEO-friendly means that your real estate website uses the terms that a search engine deems relevant to your audience. The better your SEO, the higher your website ranks. We understand the importance of being SEO-friendly to help potential clients find you.',
      },
    ],
  },
  {
    layout: 'without-image',
    title: 'Built-in Real Estate Features and Tools',
    description: [
      'Each of these real estate web designs comes with the following features and real estate tools.',
    ],
    items: [
      {
        title: 'Features',
        items: [
          'Variety of custom designs',
          'Inner pages are color-matched with your home page',
          'Your company logo and contact information',
          'Your real estate company page',
          'Updatable featured property section',
          'Ability to add MLS search (Multiple MLS capability)',
        ],
      },
      {
        title: 'Financing',
        items: [
          'Add preferred or partner lenders',
          'Ability to add logos, contact info, or brief bios for listed lenders',
          'Mapping and directions by Google',
        ],
      },
      {
        title: 'About',
        items: [
          'Upload your own profile or customize a prewritten biography',
          'Seamlessly integrated customer testimonials',
        ],
      },
      {
        title: 'Properties',
        items: [
          'Add unlimited properties',
          'Add open house info',
          'Printable flyers',
          'Virtual tour compatible',
          'Video tour (add-on)',
          'Add PDF documents',
          'Loan and amortization calculators',
          'Email specific listing to a friend',
          'Google Maps integration',
        ],
      },
      {
        title: 'Others',
        items: [
          'Add as many pages as you want through the admin module',
          'Initial submission to search engines',
          'Web statistics for tracking',
          'Ability to customize three inner page layouts*',
          'Support for one additional subdomain',
          'Support for multiple countries and currencies (check with your sales representative)',
          // 'Multiple IDX capability',
        ],
      },
      {
        title: 'Lead Generation',
        items: [
          'General inquiry form',
          '“Request a Viewing” appointment form',
          '“Find My Dream Home” form',
          '“What is My Home Worth” form',
          '“Help Me Relocate” form',
          'Spam prevention (Captcha)',
        ],
      },
      {
        title: 'Lead Management',
        items: [
          'Customizable autoresponder for each contact form',
          'Import leads from other sources',
        ],
      },
      {
        title: 'Buyer/Seller Guides and SEO-Friendly Articles',
        items: [
          'Selling/buying a home',
          'Home maintenance',
          'Mortgage and buying tips',
          'Add your own custom articles',
        ],
      },
    ],
  },
  {
    layout: 'default',
    title: 'Agent Pro <br />with iHomefinder',
    description: [
      'Stunning real estate website design along with top-notch customer service and technical support come standard with our custom websites. All Agent Pro real estate websites are engineered to help individual real estate agents or teams stand out from the crowd.',
      'Agent Pro designs are built to showcase your personal style, highlight your experience, feature useful real estate information, and allow your clients to view your listing inventory and your local MLS.',
      'This classic Agent Image website design offers 15 different themes that will bring the website you’ve always dreamed of to life.',
    ],
  },
  {
    layout: 'default-alt',
    title: 'Semi-Custom <br />with iHomefinder',
    description: [
      'Agents and brokers love our hybrid real estate websites! Semi-Custom sites include a custom homepage with all the features of our Agent Pro package. Our web designers will make sure to apply your team or brokerage’s branding to your Semi-Custom website.',
      'Our Semi-Custom websites are for leading real estate agents who maximize real estate tools to drive their business.',
    ],
    additionalTools: {
      title: 'Additional Tools',
      description:
        'In addition to the features listed above, Semi-Custom includes:',
    },
    items: [
      {
        title: 'Features',
        items: ['A full custom home page', 'Your photo', 'Inman News'],
      },
      {
        title: 'Properties',
        items: ['Slideshow photo layout'],
      },
      {
        title: 'Tips and Articles',
        items: [
          'Professionally written road maps for buyers, sellers, and financing process',
        ],
      },
    ],
  },
  {
    layout: 'default',
    title: 'Imagine Studio <br />with iHomefinder',
    description: [
      'The Imagine Studio package is an exclusive range of luxury real estate website designs that embody the essence of the Agent Image brand. These websites are inspired by the same commitment to excellence, elegance, and style embodied in every Agent Image product. This higher-tier option makes it very suitable for agents who operate in luxury real estate markets.',
      'These luxurious websites are designed for luxury real estate companies and real estate brokers.',
    ],
    items: [
      {
        title: 'Features',
        items: [
          'A full custom home page',
          'Online journal with feedback functionality',
          'Your photo',
          'Photo gallery/video gallery (stream from a third-party provider)',
          '3 module web design layouts for About, Properties, Communities, and Resources',
          'Calendar/event planner',
          'Custom image banners on inner pages*',
          'Ability to customize three inner page layouts*',
        ],
      },
      {
        title: 'About',
        items: ['View agent’s listings', 'Company profile page'],
      },
      {
        title: 'Properties',
        items: [
          'Slideshow photo layout',
          'Add a unique logo for each property',
          'Designate for sale or lease',
          'Request an appointment to view property',
          'Ability to customize three inner page layouts*',
          'Support for commercial properties for sale or lease',
          'Frameable IDX',
          'Multiple IDX capability',
        ],
      },
      {
        title: 'Lead Generation',
        items: [
          'Lead form on the listings page (pops out when users click a listing)',
        ],
      },
      {
        title: 'Financing',
        items: ['Designate for sale or lease'],
      },
      {
        title: 'Tips and Articles',
        items: [
          'Professionally written road maps for buyers, sellers, and financing process',
          'Real estate glossary',
          'Newsletter module',
          'Resource module',
        ],
      },
      {
        title: 'Internet Marketing',
        items: [
          'Internet marketing consultation',
          'Custom robots files',
          '',
          '',
        ],
      },
    ],
  },
]

export const idxFaq = [
  {
    question: 'Can a real estate agent make their own website?',
    answer: [
      'Absolutely. But for the best in real estate website design, a web designer can take your site from good to great. The design cost will pay for itself in lead generation.',
    ],
  },
  {
    question: 'How do I create a professional real estate website?',
    answer: [
      'We detail all the steps in a different blog. It can be a fairly complex task and hiring a real estate website design company allows you to focus on your work instead of the day-to-day grind of running a website. Chat with one of our Web Marketing Strategists to see what we can do for you — we offer a free consultation!',
    ],
  },
  {
    question:
      'What are some of the best real estate websites Agent Image has launched?',
    answer: [
      'We’re honored to have created real estate websites for many of the most successful agents in the industry. If you like the look of these agent websites, we can make one specifically designed for real estate professionals like you.',
    ],
    lists: [
      'The Oppenheim Group',
      'The Altman Brothers',
      'The Jills Zeder Group',
      'Shannon Gillette',
      'Kyle Blevins',
      'Quiana Watson',
      'Josh Flagg',
      'Tracy Tutor Team',
      'Aaron Kirman',
      'Sally Forster Jones',
    ],
  },
  {
    question: 'Are there Agent Image reviews?',
    answer: [
      'Yes! We are proud of our real estate website design work and designers, so we compile reviews and testimonials from our current and past clients. If you’d like to read them, check out Agent Image reviews here. (P.S. If you’ve worked with us before, be sure to leave an Agent Image review, too!)',
    ],
  },
  {
    question: 'How do I find out about Agent Image pricing?',
    answer: [
      'Just contact one of our Agent image website sales personnel for more information on rates. We offer a free consultation.',
    ],
  },
  {
    question: 'What else do I need to think about when designing a website?',
    answer: [
      'We’ve already discussed the essential features a real estate website should have. But did you know there are other factors that go into running a great website? These include maintenance costs, setup fees, add-ons, pay-per-click ads, social media management, and monthly fees, not to mention blog content that can drive web traffic and help turn potential clients into happy homeowners. If you’d like more information on these factors, contact one of our sales personnel and join the many agents who love our custom websites. Consultation is absolutely free!',
    ],
  },
  {
    question: 'Are real estate websites built on WordPress good?',
    answer: [
      'Yes. WordPress is one of the leading platforms for websites and offers plug-ins, add-ons, and a user-friendly interface. You can create your own site or talk with a custom website designer directly.',
    ],
  },
]
