import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { getImage } from 'gatsby-plugin-image'
import clsx from 'clsx'
import parse from 'html-react-parser'
import { removeElementTags } from '@utils/removeElementTags'
import useMedia, { media } from '@hooks/useMedia'

// Element
import ElementImageFile from '@components/global/element/ImageFile'

import * as cx from './ModuleTabsItem.module.scss'

import SvgCheckedCircle from '@svg/checkedCircle.inline'
import SvgArrowAlt from '@svg/arrowAlt.inline'

const ModuleTabsItem = ({
  background = 'https://resources.agentimage.com/redesign/local/idx-studio/mobile-item-background-alt.jpg',
  item,
  placeholder,
}) => {
  const desktop = useMedia(media.desktop)
  const phone = useMedia(media.phone)
  const isDeviceMinTablet = useMedia(media.minTablet)

  const contentSpace = useRef(null)
  const descriptionSpace = useRef(null)
  const additionalNoteSpace = useRef(null)

  const [active, useActive] = useState(false)
  const [isDesktop, setIsDesktop] = useState(false)
  const [isPhone, setIsPhone] = useState(false)
  const [isMinTablet, setIsMinTablet] = useState(false)

  useEffect(() => {
    setIsDesktop(desktop)
    setIsPhone(phone)
    setIsMinTablet(isDeviceMinTablet)
  }, [desktop, phone, isDeviceMinTablet])

  const mobileButton = () => {
    return (
      <button
        className={clsx(cx.readMore, active && cx.active)}
        onClick={mobileButtonToggle}
      >
        <span>Read {active ? 'Less' : 'More'}</span>
        <SvgArrowAlt />
      </button>
    )
  }

  const mobileButtonToggle = (evt) => {
    useActive((prev) => !prev)
  }

  const setInclusions = (items) => {
    return (
      <div
        ref={contentSpace}
        className={clsx(
          cx.inclusionsContent,
          active && cx.inclusionsContentActive
        )}
        style={
          active
            ? { maxHeight: contentSpace.current.scrollHeight }
            : { maxHeight: '0px' }
        }
      >
        <div className={clsx(cx.border)}></div>
        <div className={clsx(cx.inclusions)}>
          {items.map((subItem, index) => {
            return (
              <div
                key={index}
                className={clsx(cx.inclusion)}
                data-name={removeElementTags(subItem?.title)}
              >
                <h3 className="small-heading strong">
                  {parse(subItem?.title)}
                </h3>

                <ul>
                  {subItem?.items.map((subDepItem, index) => {
                    return (
                      <li
                        key={index}
                        className={clsx(
                          // cx.subItem,
                          subDepItem === '' && cx.empty
                        )}
                      >
                        {subDepItem !== '' && (
                          <>
                            <SvgCheckedCircle />
                            <span className="default-body large">
                              {parse(subDepItem)}
                            </span>
                          </>
                        )}
                      </li>
                    )
                  })}
                </ul>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  switch (item.layout) {
    case 'cards':
      return (
        <div
          className={clsx(cx.layoutCards)}
          style={{ backgroundImage: `url(${background})` }}
        >
          <div className={clsx(cx.heading)}>
            <h2
              className={clsx(
                isMinTablet && 'subtitle-2',
                !isMinTablet && 'heading-3'
              )}
            >
              {parse(item.title)}
            </h2>
            {item.description.map((description, index) => {
              return (
                <p key={index} className="default-body large">
                  {parse(description)}
                </p>
              )
            })}
          </div>
          <div
            ref={contentSpace}
            className={clsx(cx.subContent)}
            style={
              active
                ? { maxHeight: contentSpace.current.scrollHeight }
                : { maxHeight: '0px' }
            }
          >
            {item.items.map((subItem, index) => {
              return (
                <div key={index} className={clsx(cx.item)}>
                  <div className={clsx(cx.border)}></div>
                  <h3 className="subtitle-2">{parse(subItem.title)}</h3>
                  <p className="default-body large">
                    {parse(subItem.description)}
                  </p>
                </div>
              )
            })}
          </div>
          {isPhone && mobileButton()}
        </div>
      )
    case 'without-image':
      return (
        <div
          className={clsx(cx.layoutWithoutImage)}
          style={{ backgroundImage: `url(${background})` }}
        >
          <div className={clsx(cx.heading)}>
            <h2
              className={clsx(
                isMinTablet && 'subtitle-2',
                !isMinTablet && 'heading-3'
              )}
            >
              {parse(item.title)}
            </h2>
            {item.description.map((description, index) => {
              return (
                <p key={index} className="default-body large">
                  {parse(description)}
                </p>
              )
            })}
          </div>
          {setInclusions(item.items)}
          {isPhone && mobileButton()}
        </div>
      )
    default:
      return (
        <div
          className={clsx(cx.layoutInclusions)}
          style={{ backgroundImage: `url(${background})` }}
        >
          <div
            className={clsx(
              cx.subContent,
              item.layout === 'default-alt' && cx.alt
            )}
          >
            <div className={clsx(cx.heading)}>
              <div className={clsx(cx.first)}>
                <h2
                  className={clsx(
                    isMinTablet && 'subtitle-2',
                    !isMinTablet && 'heading-3'
                  )}
                >
                  {removeElementTags(item.title)}
                </h2>
                <ElementImageFile
                  className="mobile-only"
                  src={getImage(placeholder)}
                  alt={removeElementTags(item.title)}
                  lazy={true}
                />
                {item.description.map((description, index) => {
                  if (index === 0) {
                    return (
                      <p key={index} className="mobile-only default-body large">
                        {parse(description)}
                      </p>
                    )
                  }
                })}
                <div
                  ref={descriptionSpace}
                  className={clsx(cx.descriptionSpace)}
                  style={
                    active
                      ? { maxHeight: descriptionSpace.current.scrollHeight }
                      : { maxHeight: '0px' }
                  }
                >
                  {/* For Desktop */}
                  {item.description.map((description, index) => {
                    if (desktop) {
                      return (
                        <p key={index} className="default-body large">
                          {parse(description)}
                        </p>
                      )
                    }
                  })}
                  {/* For Mobile */}
                  {item.description.map((description, index) => {
                    if (index !== 0) {
                      return (
                        <p
                          key={index}
                          className="mobile-only default-body large"
                        >
                          {parse(description)}
                        </p>
                      )
                    }
                  })}
                </div>
              </div>
              {item?.additionalTools && (
                <div
                  ref={additionalNoteSpace}
                  className={clsx(cx.second, active && cx.secondActive)}
                  style={
                    active
                      ? { maxHeight: additionalNoteSpace.current.scrollHeight }
                      : { maxHeight: '0px' }
                  }
                >
                  {item?.additionalTools?.title && (
                    <h3 className="small-heading strong">
                      {parse(item.additionalTools.title)}
                    </h3>
                  )}
                  {item?.additionalTools?.description && (
                    <p className="default-body large">
                      {parse(item.additionalTools.description)}
                    </p>
                  )}
                </div>
              )}
            </div>
            {isDesktop && (
              <div className={clsx(cx.image)}>
                <ElementImageFile
                  src={getImage(placeholder)}
                  alt={removeElementTags(item.title)}
                  lazy={true}
                />
              </div>
            )}
          </div>
          {item?.items && setInclusions(item.items)}
          {isPhone && mobileButton()}
        </div>
      )
  }
}

ModuleTabsItem.propTypes = {
  background: PropTypes.string,
  item: PropTypes.object,
  placeholder: PropTypes.any,
}

export default ModuleTabsItem
