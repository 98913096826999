import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import clsx from 'clsx'
import ElementImage from '@components/global/element/Image'
import { removeElementTags } from '@utils/removeElementTags'
import parse from 'html-react-parser'
import useMedia, { media } from '@hooks/useMedia'

import { Button } from '@components/global/buttons/Buttons'
import * as cx from './PackagesCard.module.scss'

import websiteLogo from '/content/assets/idx-studio/website-logo.svg'
import idxLogo from '/content/assets/idx-studio/idx-logo.svg'

const PackagesCard = ({ className, isIdx, title, description, imgSrc }) => {
  const isDevicePhone = useMedia(media.phone)
  const [isPhone, setIsPhone] = useState(false)

  useEffect(() => {
    setIsPhone(isDevicePhone)
  }, [isDevicePhone])

  return (
    <div className={clsx(cx.wrapper, className)}>
      <div className={clsx(cx.imageContainer)}>
        <ElementImage src={imgSrc} alt={removeElementTags(title)} />
      </div>
      <div className={clsx(cx.content)}>
        {!isIdx ? (
          <ElementImage src={websiteLogo} alt="iHomefinder" />
        ) : (
          <ElementImage src={idxLogo} alt="IDX Broker" />
        )}
        <h5 className={clsx(!isPhone && 'subtitle-2', isPhone && 'heading-5')}>
          {parse(title)}
        </h5>
        <p className="default-body large">{parse(description)}</p>
        <Link to="/" className="primary-button large">
          Get features & pricing
        </Link>
      </div>
    </div>
  )
}

PackagesCard.propTypes = {
  className: PropTypes.string,
  isIdx: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  imgSrc: PropTypes.string,
}

export default PackagesCard
