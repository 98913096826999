import { useStaticQuery, graphql } from 'gatsby'

const useIDXStudio = () => {
  const query = useStaticQuery(graphql`
    query IDXStudioMetadata {
      # bannerLaptop: file(relativePath: { eq: "idx-studio/laptop-banner.png" }) {
      #   id
      #   ...IDXStudioImageOptimize
      # }
      # bannerTablet: file(relativePath: { eq: "idx-studio/tablet-banner.png" }) {
      #   id
      #   ...IDXStudioImageOptimize
      # }
      # bannerMagnify: file(relativePath: { eq: "idx-studio/magnified.png" }) {
      #   id
      #   ...IDXStudioImageOptimize
      # }
      bannerImage: file(relativePath: { eq: "idx-studio/banner.png" }) {
        id
        ...IDXStudioImageOptimize
      }
      bannerImageTablet: file(
        relativePath: { eq: "idx-studio/banner-tablet.png" }
      ) {
        id
        ...IDXStudioImageOptimize
      }
      bannerImageMobile: file(
        relativePath: { eq: "idx-studio/banner-mobile.png" }
      ) {
        id
        ...IDXStudioImageOptimize
      }
      bannerBG: file(relativePath: { eq: "idx-studio/banner-background.png" }) {
        id
        ...IDXStudioImageOptimize
      }
      featuresBG: file(
        relativePath: { eq: "idx-studio/features-background.png" }
      ) {
        id
        ...IDXStudioImageOptimize
      }
      designPackage: file(
        relativePath: { eq: "idx-studio/design-package.jpg" }
      ) {
        id
        ...IDXStudioImageOptimize
      }
      featuredImage1: file(
        relativePath: {
          eq: "idx-studio/featured-packages-images/agent-idx.png"
        }
      ) {
        id
        ...IDXStudioImageOptimize
      }
      featuredImage2: file(
        relativePath: {
          eq: "idx-studio/featured-packages-images/agent-team.png"
        }
      ) {
        id
        ...IDXStudioImageOptimize
      }
      featuredImage3: file(
        relativePath: { eq: "idx-studio/featured-packages-images/idx-team.png" }
      ) {
        id
        ...IDXStudioImageOptimize
      }
      package1: file(
        relativePath: { eq: "idx-studio/website-packages/package-1.png" }
      ) {
        id
        ...IDXStudioImageOptimize
      }
      package2: file(
        relativePath: { eq: "idx-studio/website-packages/package-2.png" }
      ) {
        id
        ...IDXStudioImageOptimize
      }
      package3: file(
        relativePath: { eq: "idx-studio/website-packages/package-3.png" }
      ) {
        id
        ...IDXStudioImageOptimize
      }
      package4: file(
        relativePath: { eq: "idx-studio/website-packages/package-4.png" }
      ) {
        id
        ...IDXStudioImageOptimize
      }
      package5: file(
        relativePath: { eq: "idx-studio/website-packages/package-5.png" }
      ) {
        id
        ...IDXStudioImageOptimize
      }
      package6: file(
        relativePath: { eq: "idx-studio/website-packages/package-6.png" }
      ) {
        id
        ...IDXStudioImageOptimize
      }
      packagesBackground: file(
        relativePath: { eq: "idx-studio/inclusions-bg.jpg" }
      ) {
        id
        ...IDXStudioImageOptimize
      }
      magnifyLaptop: file(
        relativePath: { eq: "idx-studio/magnify-laptop.png" }
      ) {
        id
        ...IDXStudioImageOptimize
      }
      magnifyTablet: file(
        relativePath: { eq: "idx-studio/magnify-tablet.png" }
      ) {
        id
        ...IDXStudioImageOptimize
      }
      mobileMagnifyTablet: file(
        relativePath: { eq: "idx-studio/mobile-magnify-tablet.png" }
      ) {
        id
        ...IDXStudioImageOptimize
      }
      idxSolutionsIpad: file(
        relativePath: { eq: "idx-studio/idx-solution-ipad.png" }
      ) {
        id
        ...IDXStudioImageOptimize
      }
      idxStudioBackground: file(
        relativePath: { eq: "idx-studio/idx-studio-bg.jpg" }
      ) {
        id
        ...IDXStudioImageOptimize
      }
      idxStudio: file(relativePath: { eq: "idx-studio/idx-studio.png" }) {
        id
        ...IDXStudioImageOptimize
      }
      idxStudioFaqBackground: file(
        relativePath: { eq: "idx-studio/idx-studio-faqs-bg.jpg" }
      ) {
        id
        ...IDXStudioImageOptimize
      }
      ModularTabs: file(
        relativePath: { eq: "idx-studio/modular-tabs-image.png" }
      ) {
        id
        ...IDXStudioImageOptimize
      }
      ModularTabsBackground: file(
        relativePath: { eq: "idx-studio/modular-tabs-bg.jpg" }
      ) {
        id
        ...IDXStudioImageOptimize
      }
      FaqBg: file(relativePath: { eq: "idx-studio/faqs/background.jpg" }) {
        id
        ...IDXStudioImageOptimize
      }
    }

    fragment IDXStudioImageOptimize on File {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, quality: 90, formats: [AUTO])
      }
    }
  `)
  return query
}

export default useIDXStudio
